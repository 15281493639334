export const routes = (parentName) => {
  return [
    {
      path: 'filters',
      component: () => import('./Index.vue'),
      children: [
        {
          path: '',
          name: `${parentName}-Filters`,
          component: () => import('./Upsert.vue'),
        },

        {
          path: 'color',
          name: `${parentName}-Filters-Color`,
          component: () => import('./Color.vue'),
        },
        {
          path: 'company',
          name: `${parentName}-Filters-Company`,
          component: () => import('./Company.vue'),
        },

        {
          path: 'mdoel',
          name: `${parentName}-Filters-Model`,
          component: () => import('./Model.vue'),
        },
        {
          path: 'rouye',
          name: `${parentName}-Filters-Rouye`,
          component: () => import('./Rouye.vue'),
        },
        {
          path: 'astar',
          name: `${parentName}-Filters-Astar`,
          component: () => import('./Astar.vue'),
        },

        {
          path: 'kafi',
          name: `${parentName}-Filters-Kafi`,
          component: () => import('./Kafi.vue'),
        },

        {
          path: 'zire',
          name: `${parentName}-Filters-Zire`,
          component: () => import('./Zire.vue'),
        },

        {
          path: 'type1',
          name: `${parentName}-Filters-Type1`,
          component: () => import('./Type1.vue'),
        },

        {
          path: 'type2',
          name: `${parentName}-Filters-Type2`,
          component: () => import('./Type2.vue'),
        },
      ],
    },
  ];
};
