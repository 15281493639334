export default {
  "Email_or_phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ایمیل یا شماره موبایل"])},
  "Enter_the_email_address_or_phone_number_you_used_when_you_joined_and_we'll_send_you_instructions_to_reset_your_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شماره موبایل یا ایمیل خود را وارد کنید"])},
  "IR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IR"])},
  "There_is_a_problem_Please_check_the_following_Inputs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مشکلی رخ داده است لطفا مقدار ورودی هارو چک کنید"])},
  "about_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["درباره ما"])},
  "account_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شماره حساب"])},
  "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اقدام"])},
  "activated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فعال"])},
  "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فعال"])},
  "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["افزودن"])},
  "add_camera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["افزودن دوربین"])},
  "add_new_camera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافه کردن دوربین جدید"])},
  "add_new_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["افزودن کارت جدید"])},
  "add_new_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["افزودن آیتم جدید"])},
  "add_new_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["افزودن کاربر جدید"])},
  "add_product_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فرم افزودن کالا"])},
  "add_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافه کردن نقش"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نشانی"])},
  "administrator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادمین"])},
  "alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هشدار"])},
  "alert_activated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هشدار فعال شد"])},
  "alert_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هشدار غیر فعال شد"])},
  "alerts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هشدارها"])},
  "all_zones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["همه مناطق"])},
  "analytics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تجزیه و تحلیل"])},
  "api_base_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نشانی اصلی API ها"])},
  "api_documentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مستندات API"])},
  "asphalt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["آسفالت"])},
  "average_age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["میانگین سن"])},
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["برگشت"])},
  "back_to_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["برگشت به صفحه ورود"])},
  "bank_account_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اطلاعات حساب بانکی"])},
  "bank_melli_iran": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بانک ملی ایران"])},
  "bank_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نام بانک"])},
  "basic_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اطلاعات اولیه"])},
  "birth_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاریخ تولد"])},
  "blog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بلاگ"])},
  "browser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مرورگر"])},
  "building": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ساختمان"])},
  "buildings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ساختمان‌ها"])},
  "camera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دوربین"])},
  "camera_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["گروه دوربین‌ها"])},
  "cameras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دوربین‌ها"])},
  "cameras_in_this_zone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دوربین‌های این منطقه"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لغو"])},
  "cancel_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لغو همه"])},
  "card_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شماره کارت"])},
  "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تغییر دادن"])},
  "chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نمودار"])},
  "choose_an_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["یک گزینه را انتخاب کنید"])},
  "choose_your_desired_point": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نقطه مورد نظر خود را انتخاب کنید"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بستن"])},
  "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رنگ"])},
  "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["کامنت"])},
  "common": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مشترک"])},
  "confirm_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رمز عبور را تایید کنید"])},
  "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تماس با ما"])},
  "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مخاطب‌ها"])},
  "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["کپی"])},
  "copyright_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متن کپی رایت"])},
  "create_your_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ایجاد حساب کاربری"])},
  "cut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بریدن"])},
  "dangerous_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هشدار خطرناک"])},
  "dark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاریک"])},
  "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["داشبرد"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاریخ"])},
  "deactivated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غیرفعال"])},
  "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["پیش فرض"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حذف"])},
  "delete_all_dossiers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حذف تمام پرونده‌ها"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["توضیحات"])},
  "developer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["توسعه دهنده"])},
  "device": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دستگاه"])},
  "devices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دستگاه‌ها"])},
  "dictance_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فاصله از"])},
  "dictance_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فاصله تا"])},
  "disable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غیر فعال"])},
  "distance_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فاصله از"])},
  "distance_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فاصله تا"])},
  "documentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مستندات"])},
  "dossiers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["پرونده‌ها"])},
  "download_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["گزینه های دانلود"])},
  "duplicate_events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رویدادهای تکراری"])},
  "duplicate_intervals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فواصل تکراری"])},
  "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ویرایش"])},
  "editing_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ویرایش اطلاعات"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ایمیل"])},
  "employee_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["کد پرسنلی"])},
  "employee_contact_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اطلاعات تماس پرسنلی"])},
  "employee_photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عکس پرسنلی"])},
  "employees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["کارمندان"])},
  "enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فعال"])},
  "episods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قسمت‌ها"])},
  "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رویداد"])},
  "event_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سطح رویداد"])},
  "event_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نام رویداد"])},
  "event_point": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نقطه رویداد"])},
  "event_threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["آستانه رویداد"])},
  "events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رویدادها"])},
  "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خروجی"])},
  "extensions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["افزونه‌ها"])},
  "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ناموفق"])},
  "fax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فکس"])},
  "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نام"])},
  "footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فوتر"])},
  "forget_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فراموشی رمز عبور"])},
  "form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فرم"])},
  "fullname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نام کامل"])},
  "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جنسیت"])},
  "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عمومی"])},
  "general_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تنظیمات عمومی"])},
  "good_bye": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خدافظ"])},
  "hello": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سلام"])},
  "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاریخچه"])},
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خانه"])},
  "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اطلاعات"])},
  "information_edited_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اطلاعات با موفقیت ویرایش شدند"])},
  "information_has_been_successfully_registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اطلاعات با موفقیت ثبت شد"])},
  "internal_validation_token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["توکن اعتبارسنجی داخلی"])},
  "job_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["موقعیت شغلی"])},
  "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["برچسب"])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["زبان"])},
  "languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["زبان‌ها"])},
  "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نام خانوادگی"])},
  "latest_events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["آخرین رویدادها"])},
  "license": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مجوز"])},
  "light": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["روشن"])},
  "limits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["محدودیت‌ها"])},
  "line_chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نمودار خطی"])},
  "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لینک"])},
  "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لیست"])},
  "list_of_all_cameras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لیست تمام دوربین‌ها"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ورود"])},
  "login_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لینک ورود"])},
  "login_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صفحه ورود"])},
  "loginlogout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ورود و خروج"])},
  "main_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنوان اصلی"])},
  "manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مدیر"])},
  "manager_area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ناحیه مدیر"])},
  "manager_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["داشبرد مدیر"])},
  "managers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مدیرها"])},
  "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نقشه"])},
  "map_iframe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["آیفرم نقشه"])},
  "material": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مواد"])},
  "meter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متر"])},
  "mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تلفن همراه"])},
  "model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مدل"])},
  "monitoring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نظارت"])},
  "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بیشتر"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نام"])},
  "national_card_photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عکس کارت ملی"])},
  "national_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["کد ملی"])},
  "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جدید"])},
  "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بعدی"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خیر"])},
  "no_data_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["داده‌ای موجود نیست"])},
  "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هیچ"])},
  "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اطلاعیه‌ها"])},
  "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تایید"])},
  "online_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["گزارش آنلاین"])},
  "operator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اپراتور"])},
  "operator_area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ناحیه اپراتور"])},
  "operators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اپراتورها"])},
  "options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["گزینه ها"])},
  "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ترتیب"])},
  "os": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سیستم عامل"])},
  "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["برگه"])},
  "pages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["برگه ها"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["گذرواژه"])},
  "paste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جای‌گذاری"])},
  "pending_to_checking_Login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["در حال بررسی"])},
  "permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مجوزها"])},
  "personnel_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["کد پرسنلی"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تلفن"])},
  "phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شماره تلفن"])},
  "places": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مکان‌ها"])},
  "plaque": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["پلاک"])},
  "please_wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لطفا صبر کنید "])},
  "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قبلی"])},
  "print": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["چاپ"])},
  "priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اولویت"])},
  "product_edit_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فرم ویرایش کالا"])},
  "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["پروفایل"])},
  "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["موجودی"])},
  "quantum_random_numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اعداد تصادفی کوانتومی"])},
  "rando_number_requests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["درخواست‌های اعداد تصادفی"])},
  "redo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بازگرداندن"])},
  "register_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صفحه ثبت‌نام"])},
  "reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["گزارش‌ها"])},
  "roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نقش‌ها"])},
  "row": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ردیف"])},
  "salary_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مبلغ حقوق"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ذخیره"])},
  "script": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسکریپت"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جستجو"])},
  "search_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جستجو برای"])},
  "search_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جستجو در"])},
  "search_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نتایج جستجو"])},
  "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انتخاب"])},
  "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انتخاب همه"])},
  "select_device": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انتخاب دستگاه"])},
  "select_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انتخاب رویداد"])},
  "select_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انتخاب فایل"])},
  "select_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انتخاب زبان"])},
  "select_zone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انتخاب منطقه"])},
  "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ارسال"])},
  "sensor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حسگر"])},
  "sensors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حسگرها"])},
  "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سرویس‌ها"])},
  "setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تنظیمات"])},
  "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تنظیمات"])},
  "shaba_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شماره شبا"])},
  "sign_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خروج"])},
  "soil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خاک"])},
  "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وضعیت"])},
  "stone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سنگ"])},
  "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ارسال"])},
  "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["موفق"])},
  "supervisor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سرپرست"])},
  "support_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متن پشتیبانی"])},
  "symbols": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نمادها"])},
  "symbols_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لیست نمادها"])},
  "system_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اطلاعات سیستم"])},
  "the_camera_has_been_registered_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دوربین با موفقیت ثبت شد"])},
  "the_device_has_been_registered_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دستگاه با موفقیت ثبت شد"])},
  "the_number_of_bits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعداد بیت"])},
  "the_previous_token_was_invalidated_and_replaced_with_a_new_token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["توکن قبلی باطل و توکن جدید جایگزین شد"])},
  "the_title_of_the_links": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنوان لینک ها"])},
  "the_title_of_the_symbols": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنوان نماد ها"])},
  "the_zone_has_been_registered_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["منطقه با موفقیت ثبت شد"])},
  "threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["آستانه"])},
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنوان"])},
  "title_support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنوان پشتیبانی"])},
  "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["امروز"])},
  "tools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ابزارها"])},
  "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع"])},
  "undo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["برگرداندن"])},
  "upload_center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مرکز آپلود"])},
  "upper_bound_of_the_chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["کران بالای نمودار"])},
  "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["کاربر"])},
  "user_area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ناحیه کاربر"])},
  "user_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["داشبرد کاربر"])},
  "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نام کاربری"])},
  "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["کاربران"])},
  "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مقدار"])},
  "verify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تایید"])},
  "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مشاهده"])},
  "view_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مشاهده لیست"])},
  "visitors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بازدید کنندگان"])},
  "watch_lists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لیست تماشا"])},
  "water": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["آب"])},
  "waterfall_chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["چارت آبشاری"])},
  "webhook_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنوان وب هوک"])},
  "webhooks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وب هوک‌ها"])},
  "website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وب سایت"])},
  "working_hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ساعات کاری"])},
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بله"])},
  "yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دیروز"])},
  "zone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["منطقه"])},
  "zone_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نام منطقه"])},
  "zones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مناطق"])}
}